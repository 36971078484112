import {
  Configuration,
  CommentReactionsConfig,
  ReplyReactionsConfig,
} from '@wix/comments-ooi-client';
import {
  CommentLabelType,
  ReplyReactionType,
  ReactionType as CommentReactionType,
  ReactionIconType,
} from '../../../services/category-service';
import { unreachable } from '../../../ts-utils';
import { TFunction } from '@wix/yoshi-flow-editor';
import { WidgetSettings } from './get-widget-settings';
import { ReactionName } from '@wix/comments-ooi-client/dist/types/reactions/reaction-types';
import { resolveAdditionalReactions } from '../Settings/settings-tab/reactions-section/resolve-additional-reactions.util';
import { CommentBoxPositionOptions } from '~common/common-types';

export const resolveCommentsConfig = ({
  settings,
  t,
}: {
  settings: WidgetSettings;
  t: TFunction;
}): Partial<Omit<Configuration, 'pluginConfig' | 'pagination'>> => {
  const commentReactions = resolveCommentReactions(
    settings.reactionType,
    settings.mainReaction,
    settings.additionalReactions.reactions,
  );

  const replyReactions = resolveReplyReactions(
    settings.repliesReactionType,
    settings.mainReaction,
    settings.additionalReactions.reactions,
  );

  return {
    roleBadges: settings.isAdminBadgeEnabled
      ? {
          OWNER: { label: settings.adminBadgeText },
          CONTRIBUTOR: { label: settings.adminBadgeText },
        }
      : {},
    hideHeaderDivider: !settings.isDividerEnabled,
    hideSorting: !settings.isSortingEnabled,
    hideTitle: !settings.isCommentsTitleEnabled,
    hideReplyingTo: !settings.isReplyingToMarkingEnabled,
    hideAvatars: !settings.isAvatarsEnabled,
    hideBadge: !settings.isMembersBadgeEnabled,
    hidePublishDate: !settings.isPublishDateEnabled,
    commentBoxPosition:
      settings.commentBoxPosition === CommentBoxPositionOptions.auto
        ? undefined
        : settings.commentBoxPosition,
    reactions:
      commentReactions || replyReactions
        ? {
            commentReactions,
            replyReactions,
          }
        : undefined,
    markLabels: resolveMarkLabelsConfig({ settings, t }),
    userAuthDisplay: settings.isHeaderLoginEnabled ? 'LOGIN_AND_LOGOUT' : 'NONE',
    likeButtonText: settings.likeButtonText,
    replyButtonText: settings.replyButtonText,
    placeholderText: settings.placeholderText,
    publishButtonText: settings.publishButtonText,
    placeholderTextReply: settings.placeholderTextReply,
    publishButtonTextReply: settings.publishButtonTextReply,
    headerTitleText: (count: number) =>
      t('settings.textTab.headerTitleText', {
        title: settings.headerTitle,
        count: settings.isCommentsCountEnabled ? count : 0,
      }),
    ratings: settings.ratingsSettings?.ratingsEnabled
      ? {
          isRequired: settings.ratingsSettings.ratingRequired ?? false,
          addRatingText: settings.ratingSelectorText,
        }
      : undefined,
  };
};

const resolveCommentReactions = (
  reactionType: WidgetSettings['reactionType'],
  mainReaction: ReactionIconType | undefined,
  additionalReactions: WidgetSettings['additionalReactions']['reactions'],
): CommentReactionsConfig | undefined => {
  const mainEmotion = mainReaction === ReactionIconType.THUMBS_UP ? 'thumbsup' : 'heart';
  const filteredAdditionalReactions = resolveAdditionalReactions(
    additionalReactions,
    mainReaction,
  ) as ReactionName[];
  switch (reactionType) {
    case CommentReactionType.MULTIPLE_REACTIONS: {
      return {
        type: 'emotions',
        mainEmotion,
        emotions:
          filteredAdditionalReactions.length > 0
            ? (filteredAdditionalReactions as [ReactionName])
            : undefined,
      };
    }
    case CommentReactionType.REACTIONS: {
      return {
        type: 'likes',
        mainEmotion,
      };
    }
    case CommentReactionType.VOTE: {
      return { type: 'votes' };
    }
    case CommentReactionType.UNKNOWN:
      return { type: 'none' };
    case undefined:
      return undefined;
    default: {
      unreachable(reactionType);
    }
  }
};

const resolveReplyReactions = (
  reactionType: WidgetSettings['repliesReactionType'],
  mainReaction: ReactionIconType | undefined,
  additionalReactions: WidgetSettings['additionalReactions']['reactions'],
): ReplyReactionsConfig | undefined => {
  const mainEmotion = mainReaction === ReactionIconType.THUMBS_UP ? 'thumbsup' : 'heart';
  const filteredAdditionalReactions = resolveAdditionalReactions(
    additionalReactions,
    mainReaction,
  ) as ReactionName[];
  switch (reactionType) {
    case ReplyReactionType.MULTIPLE_REACTIONS: {
      return {
        type: 'emotions',
        mainEmotion,
        emotions:
          filteredAdditionalReactions.length > 0
            ? (filteredAdditionalReactions as [ReactionName])
            : undefined,
      };
    }
    case ReplyReactionType.REACTIONS: {
      return { type: 'likes', mainEmotion };
    }
    case ReplyReactionType.UNKNOWN:
      return { type: 'none' };
    case undefined: {
      return undefined;
    }
    default: {
      unreachable(reactionType);
    }
  }
};

const resolveMarkLabelsConfig = ({ settings, t }: { settings: WidgetSettings; t: TFunction }) => {
  const { markedCommentLabelType, customMarkedCommentLabel } = settings;
  switch (markedCommentLabelType) {
    case CommentLabelType.BEST_ANSWER:
      return {
        markActionLabel: t('markLabel.bestAnswer.markAction'),
        unmarkActionLabel: t('markLabel.bestAnswer.unmarkAction'),
        badgeLabel: t('markLabel.bestAnswer.label'),
      };
    case CommentLabelType.FEATURED:
      return {
        markActionLabel: t('markLabel.featured.markAction'),
        unmarkActionLabel: t('markLabel.featured.unmarkAction'),
        badgeLabel: t('markLabel.featured.label'),
      };
    case CommentLabelType.PINNED:
      return {
        markActionLabel: t('markLabel.pinned.markAction'),
        unmarkActionLabel: t('markLabel.pinned.unmarkAction'),
        badgeLabel: t('markLabel.pinned.label'),
      };
    case CommentLabelType.SOLVED:
      return {
        markActionLabel: t('markLabel.solved.markAction'),
        unmarkActionLabel: t('markLabel.solved.unmarkAction'),
        badgeLabel: t('markLabel.solved.label'),
      };
    case CommentLabelType.CUSTOM:
      if (customMarkedCommentLabel) {
        return {
          markActionLabel: t('markLabel.custom.markAction', {
            customLabel: customMarkedCommentLabel,
          }),
          unmarkActionLabel: t('markLabel.custom.unmarkAction', {
            customLabel: customMarkedCommentLabel,
          }),
          badgeLabel: customMarkedCommentLabel,
        };
      } else {
        return undefined;
      }
    case CommentLabelType.UNKNOWN:
    case undefined:
      return undefined;
    default:
      unreachable(markedCommentLabelType);
  }
};
